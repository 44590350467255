import { orderBy, SortDescriptor } from '@progress/kendo-data-query'
import { Grid, GridColumn, GridPageChangeEvent, GridSortChangeEvent, GridToolbar } from '@progress/kendo-react-grid'
import { ExcelExport } from '@progress/kendo-react-excel-export'
import * as Dropdown from 'components/dropdown/component'
import { HeaderComponent } from 'components/header/component'
import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import { addDays, parseISO } from 'date-fns'
import { utcToZonedTime, format } from 'date-fns-tz'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import { Dataset, DatasetParam } from 'generic/dataset'
import * as GenericRedux from 'generic/genericRedux'
import { RasaReactComponent } from 'generic/rasaReactComponent'
import * as React from 'react'
import { Button, Col, Input, Row } from 'reactstrap'
import { DEFAULT_TIMEZONE } from 'shared_server_client/constants'
import { startOfDay } from 'shared_server_client/dates'

import { FormatDropdownSelector, EmojiDropdownSelector } from '../constants'
import { GenerateTextComponent } from '../generate'
import { RecommendationType } from './constants'

import './_styles.scss'

interface Pagination {
  skip: number,
  take: number,
}

interface Person {
  address: string,
  first_name: string,
  id: string,
  last_name: string,
  engine?: string,
  score?: string,
  model?: string,
}

interface Event {
  event_time: Date,
  event_type: string,
  format_type: string,
  description: string,
  duration: string,
  speaker: string,
  id: string,
  registration_url: string,
  title: string,
  url: string,
}

interface State {
  error?: any,
  events: Event[],
  input: string,
  isDirty: boolean,
  isThinking: boolean,
  maxResults: number,
  pagination: Pagination,
  people: Person[],
  selectedEvent: Event,
  selectedCommunity: string,
  sort: SortDescriptor[]
  timezone: string,
}

const eventSummary = (event: Event, timeZone: string): string => {
  const parts = [
    event.title
  ]
  if ( event.event_time ) {
    const utcZonedTime = utcToZonedTime(event.event_time, timeZone)
    const formatted = format(utcZonedTime, 'yyyy-MM-dd @ HH:mm', { timeZone })
    parts.push(formatted)
  }
  if ( event.speaker ) {
    parts.push(event.speaker)
  }
  return parts.join(' - ')
}

const buildEvent = (input: any): Event => {
  const options = input.options || {}
  return {
    description: input.description,
    duration: options.duration,
    event_time: options.start_time ? parseISO(input.options.start_time) : null,
    event_type: options.entity_type,
    format_type: input.format_type,
    speaker: options.speaker,
    id: input.post_subscription_id,
    registration_url: options.registration_url,
    title: input.title,
    url: input.url,
  }
}

const isWebinar = (event: Event): boolean => {
  return event.event_type === 'webinar' || event.format_type === 'Event'
}

const emptyState: State = {
  events: [],
  input: '',
  isDirty: false,
  isThinking: false,
  maxResults: 100,
  pagination: {
    skip: 0,
    take: 10,
  },
  people: [],
  selectedCommunity: '',
  selectedEvent: null,
  sort: [],
  timezone: 'America/Chicago',
}

class RexWebinarComponent extends RasaReactComponent<any, State> {
  public static contextType = RasaContext;
  private xlsxExport: any = null

  constructor(props: any) {
    super(props, "rexWebinar", emptyState)
  }

  public componentDidMount = () => {
    this.context.user.init().then(({person, activeCommunity}) => {
      const timezone: string = (activeCommunity.data || {}).company_time_zone || DEFAULT_TIMEZONE

      const params: DatasetParam[] = [
        { param: 'startDate', value: startOfDay(new Date(), this.state.timezone).toISOString()},
        { param: 'endDate', value: startOfDay(addDays(new Date(), 90), this.state.timezone).toISOString()},
      ]
      return new Dataset().loadCommunityDataset(
        'scheduledContent',
        activeCommunity.communityId,
        params
      ).then((results) => {
        const webinars: Event[] = results[0].map((x) => buildEvent(x)).filter((x) => isWebinar(x))
        this.setState({
          events: webinars,
          input: webinars.length > 0 ? webinars[0].description : '',
          isDirty: true,
          selectedCommunity: activeCommunity.communityId,
          selectedEvent: webinars.length > 0 ? webinars[0] : null,
          timezone
        })
      })
    })
  }

  public render = () => {
    return <div className="rex-webinar-demo">
      <HeaderComponent
        icon="t-rex"
        title={'Rex Webinar Demonstration'}
        subTitle='Let Rex Help you drive Webinar Signups!'
        description={[
          'Select one of your upcoming webinars, and t-Rex will identify audience members who are likely to be interested in attending.',
        ]}
      />
      <Row className="webinar">
        <Col className="summary">
          <h5>Select a Webinar</h5>
          <Dropdown.DropdownComponent className="webinar-selector"
                                      data={this.webinarsAsDropdowns()}
                                      selected={""}
                                      onChange={(e) => {
                                        // TODO: Set the selected event
                                        const selectedEvent = this.state.events.find((event) => event.id === e.selected.key)
                                        this.setState({
                                          isDirty: true,
                                          input: selectedEvent.description,
                                          selectedEvent,
                                        })}}
                                      />

          <h5>Summary</h5>
          <Input type="textarea"
                value={this.state.input}
                disabled={this.state.isThinking}
                rows="5"
                onChange={(e) => this.setState({
                  isDirty: true,
                  input: e.target.value,
                })} />
        </Col>
        <Col className="ask-rex">
          <Button disabled={!this.canAsk()}
                  onClick={this.askRex}>
            Suggest Attendees
          </Button>
        </Col>
      </Row>
    { this.state.isThinking
    ? <Loading size="32"/>
    : ( this.state.people.length > 0 && this.showSuggestions() )
    }
    </div>
  }

  private personsToDisplay = (): any[] => {
    const orderedData: any[] = orderBy(this.state.people, this.state.sort)
    return orderedData.slice(this.state.pagination.skip, this.state.pagination.skip + this.state.pagination.take)
  }

  private sort = (e: GridSortChangeEvent) => {
    this.setState({
      sort: e.sort,
    })
  }

  private page = (e: GridPageChangeEvent) => {
    this.setState({
      pagination: {
        skip: e.page.skip,
        take: e.page.take,
      }
    })
  }

  private webinarsAsDropdowns = (): Dropdown.DropdownOption[] => {
    return this.state.events.map((event: Event) => ({
      key: event.id,
      description: eventSummary(event, this.state.timezone),
      value: event,
    }))
  }

  private showSuggestions = () => {
    return <Row className="results">
      <Col className="suggestions">
        {this.suggestionTable()}
      </Col>
      <Col className="generate">
        {this.createEmail()}
      </Col>
    </Row>
  }

  private createEmail = () => {
    return <div>
      <GenerateTextComponent communityIdentifier={this.state.selectedCommunity}
        selectors={[ EmojiDropdownSelector, FormatDropdownSelector ]}
        responsesTitle="Draft an Email"
        aiPromptType="rex_generate_email"
        description={this.rexDescription()}/>
    </div>
  }

  private rexDescription = (): string => {
    const webinar: Event = this.state.selectedEvent
    if ( !webinar ) {
       return ''
    }

    const parts: string[] = [
      `Title: ${webinar.title}`,
      `Description: ${webinar.description}`,
    ]
    if ( webinar.speaker ) {
      parts.push(`Speaker: ${webinar.speaker}`)
    }
    return `Pleae write an email inviting a user to attend this webinar: ${parts.join('\n')}`
  }

  private suggestionTable = () => {
    return <div className="suggestion-table">
      <ExcelExport data={this.state.people}
                   fileName="WebinarMailingList.xlsx"
                   ref={(exporter) => {this.xlsxExport = exporter}}>
          <Grid data={this.personsToDisplay()}
                sortable={{
                  allowUnsort: true,
                }}
                sort={this.state.sort}
                onSortChange={(e: GridSortChangeEvent) => this.sort(e)}
                skip={this.state.pagination.skip}
                take={this.state.pagination.take}
                total={this.state.people.length}
                pageSize={this.state.pagination.take}
                onPageChange={(e: GridPageChangeEvent) => this.page(e)}
                pageable={true}>
            <GridToolbar>
              <Button disabled={this.state.people.length < 1}
                      onClick={() => this.xlsxExport.save()}>
                Export xlsx
              </Button>
            </GridToolbar>
            <GridColumn field="address" title="email" cell={this.tooltipCell}/>
            <GridColumn field="first_name" cell={this.tooltipCell}/>
            <GridColumn field="last_name" cell={this.tooltipCell}/>
          </Grid>
        </ExcelExport>
      </div>
  }

  private tooltipCell = (props: any) => {
    const tooltip: string = `Score: ${props.dataItem.score}\nEngine: ${props.dataItem.engine}\nModel: ${props.dataItem.model}`
    return <td title={tooltip}>
      {props.dataItem[props.field]}
    </td>
  }

  private canAsk = (): boolean => {
    return (this.state.input || '').length > 5 && !this.state.isThinking && this.state.isDirty
  }

  private askRex = () => {
    this.setState({
      people: [],
      isThinking: true
    }, () => {
      AjaxWrapper
        .ajax(`${AjaxWrapper.getServerUrl()}/rex/${this.state.selectedCommunity}/find`, HttpMethod.POST, {
          application: 'dashboard',
          input: this.state.input,
          identifier: this.state.selectedCommunity,
          filters: [
            {
              type: RecommendationType.PERSON,
              max_results: this.state.maxResults,
            }
          ]
        })
        .then((results) => this.setState({
          isDirty: false,
          isThinking: false,
          people: results.person,
        }))
        .catch((error) =>  this.setState({
          error,
          isThinking: false,
        }))
    })
  }
}

export const RexWebinar = GenericRedux.createConnect(
  RexWebinarComponent,
  "rex_webinar",
)
